<template>
    <section class="hold-transition bg-img" style="background-image:url(/assets/images/bg-2.jpg)" data-overlay=5>
        <div class="container pt-3 h-p100">
            <div class="row h-p100 justify-content-sm-center align-items-center">
                <div class="col-sm-6 col-md-4">

                    <div class="card border-info text-center">
                        <div class="card-header">
                        FTD Admin
                        </div>
                        <div class="card-body">
                        <img src="@/assets/images/ftd_logo_1.png" class="img-fluid rounded-circle w-150 mb-10">
                        <h4 class="text-center mb-20">Sign In</h4>
                        <p v-if="error" class="error">{{errorMessage}}</p>
                        <form @submit.prevent="onSubmit" class="form-signin">
                            <input type="email" v-model="email" class="form-control mb-2" placeholder="Email" required autofocus>
                            <input type="password" v-model="password" class="form-control mb-2" placeholder="Password" required>
                            <button v-if="!loading" class="btn btn-lg btn-primary btn-block mb-20" @click="signInAdmin">Sign in</button>
                            <div v-else class="spinner-border text-success"></div>
                            <div class="checkbox float-left">
                                <input type="checkbox" id="basic_checkbox_1" >
                                <label for="basic_checkbox_1">Remember me</label>
                            </div>
                            <router-link :to="{name: 'ForgotPassword'}" class="float-right">Forgot Password?</router-link>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { repository } from '../../presenter'
export default {
  data () {
    return {
      email: '',
      password: '',
      error: false,
      errorMessage: '',
      loading: false
    }
  },
  methods: {
    resetData () {
      this.error = false
      this.errorMessage = ''
      this.loading = false
    },
    async signInAdmin () {
      this.resetData()
      this.loading = true
      const response = await repository.adminLogin(this.email, this.password)
      if (response.success) {
        this.showDashboard()
      } else {
        console.log(response)
        console.log(response.data)
        this.showError(response.data)
        this.loading = false
      }
    },
    showDashboard () {
      // this.$router.replace('/')
      window.location.href=process.env.BASE_URL;
    },
    showError (message) {
      this.error = true
      this.errorMessage = message
    }
  }
}
</script>

<style scoped>
.bg-img {
    height: 100vh;
}
</style>
